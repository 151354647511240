import {
  Swiper,
  Navigation,
  Autoplay,
  EffectCoverflow,
  EffectFade,
  Pagination,
  FreeMode,
  Thumbs,
} from "swiper";

import "swiper/css"; // core
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";
import "swiper/css/free-mode";
import "swiper/css/effect-coverflow";
import "swiper/css/effect-fade";

// customize color of navigation because I can't get the css variable to work
import "./swiper.scss";

const modules = [
  Autoplay,
  Navigation,
  Pagination,
  Thumbs,
  FreeMode,
  EffectCoverflow,
  EffectFade,
];

/**
 * Preload next slide(s) images in the swiper by setting the src and srcset attributes
 * from the data-src and data-srcset attributes.
 *
 * This loops through the next 1 or 2 slides and sets the src and srcset attribute.
 * fromIndexDelta is the number of slides before the current slide to load.
 * tillIndexDelta is the number of slides after the current slide to load.
 *
 * To load the next slide, use loadNextSlide(swiper, 0, 1)
 * To load for a thumbnail swiper, use loadNextSlide(swiper, -2, 2)
 * where the focused thumbnail is in the middle.
 */
function loadNextSlide(swiper: Swiper, fromIndexDelta = 0, tillIndexDelta = 1) {
  const activeIndex = swiper.activeIndex;

  function loadSlide(index: number) {
    const nextSlide = swiper.slides[index];
    // console.log(`loadSlide(${index})`, {
    //   index,
    //   activeIndex,
    //   fromIndexDelta,
    //   tillIndexDelta,
    //   nextSlide,
    // });
    const nextImage = nextSlide?.querySelector("img");
    // console.log("nextImage to load:", nextImage?.src, nextImage?.dataset.src);
    if (nextImage && !nextImage.src) {
      // console.log("loading image", index, nextImage.dataset.src);
      nextImage.src = nextImage.dataset.src;
      nextImage.srcset = nextImage.dataset.srcset;
      nextImage.loading = "eager";
    } else {
      const nextVideo = nextSlide?.querySelector("video");
      if (nextVideo) {
        for (const source in nextVideo.children) {
          const videoSource: any = nextVideo.children[source];
          if (
            typeof videoSource.tagName === "string" &&
            videoSource.tagName === "SOURCE"
          ) {
            videoSource.src = videoSource.dataset.src;
          }
        }
        if (nextVideo.classList.contains("lazy")) {
          nextVideo.load();
          nextVideo.classList.remove("lazy");
        }
      }
    }
  }

  for (let i = fromIndexDelta; i <= tillIndexDelta; i++) {
    let wrappedIndex = activeIndex + i;
    if (wrappedIndex >= swiper.slides.length) {
      wrappedIndex = wrappedIndex - swiper.slides.length;
    } else if (wrappedIndex < 0) {
      wrappedIndex = swiper.slides.length + wrappedIndex;
    }
    loadSlide(wrappedIndex);
  }
}

function restartAutoplay(swiper: Swiper) {
  const activeIndex = swiper.activeIndex;
  const slide = swiper.slides[activeIndex];
  const video = slide?.querySelector("video");
  if (video) {
    if (!video.classList.contains("lazy")) {
      video.pause();
      video.currentTime = 0;
      video.play();
    }
  }
}

/**
 * On window load, dispatch a custom event with the Swiper class and modules.
 *
 * window.addEventListener("SwiperDidLoad", function (event) {
 *   //  event.detail.Swiper
 *   //  event.detail.modules
 * });
 *
 * This allows the templates that need Swiper to include javascript
 * to initialize Swiper even though the Swiper javascript is loaded after the
 * template javascript.
 */
if (!window["swiperIntialized"]) {
  window.addEventListener("load", function () {
    window.dispatchEvent(
      new CustomEvent("SwiperDidLoad", {
        detail: {
          Swiper,
          modules,
          loadNextSlide,
          restartAutoplay,
        },
      }),
    );
  });
  // webpack devserver loads this file about 6 times, so only register the event handler once
  window["swiperIntialized"] = true;
}

export default Swiper;
